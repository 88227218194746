.project {
    width: 300px;
    min-width: 250px;
    opacity: 0;
}

.project.visible {
    animation: fadeIn 1s ease-in both;
}

.img {
    width: 100%;
    height: 226px;
    max-height: 226px;
    border-radius: 10px;
    object-fit: cover;
}

.img:hover {
    scale: 1.2;
    transition: 0.8s;
}

.title {
    padding: 20px 0;
}

.action {
    margin-bottom: 20px;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.skills > span ~ span {
    margin-left: 0 !important;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, 0%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}