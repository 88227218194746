.skill {
    --_background: var(--skill-tag-bg, hsl(20, 32%, 36%));
    --_color: var(--skill-tag-text, hsl(21, 55%, 93%));
    display: inline-block;
    background-color: var(--_background);
    padding: 5px 10px;
    font-size: 1.3rem;
    border-radius: 40px;
    color: var(--_color);
    word-break: break-all;
}

.skill ~ .skill {
    margin-left: 10px;
}