@font-face {
  font-family: "GelasioRegular";
  src: local("GelasioRegular"),
    url(./assets/fonts/Gelasio-Regular.woff) format("woff");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --headline-color: #ebccbb;
  --text-color: #c9c2c0;
  --bg-hsl: 15, 3%, 27%;
  --background-color: hsl(var(--bg-hsl));

  --skill-language: #855E51;
  --skill-framework: #C46647;
  --skill-other: #519A89;
  --skill-tag-language-bg: #B37E6D;
  --skill-tag-language-text: #371313;
  --skill-tag-framework-bg: #F27E57;
  --skill-tag-framework-text: #2e0000;
  --skill-tag-other-bg: #69C7B1;
  --skill-tag-other-text: #332824;
}

html {
  font-size: 0.625rem;
}

body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: hsl(20, 32%, 66%);
}

.title {
  font-size: 7.5rem;
}

h1 {
  font-size: 5.4rem;
}
h2 {
  font-size: 4.5rem;
}
h3 {
  font-size: 3.6rem;
}
h4 {
  font-size: 2.6rem;
}

h1,
h2,
h3,
h4 {
  color: var(--headline-color);
}

p,
span,
div,
a,
ul,
ol,
li,
td,
label,
textarea {
  color: var(--text-color);
  font-size: 1.8rem;
}

h1,
h2,
h3,
h4,
p,
span,
div,
a,
ul,
ol,
li,
td,
label,
textarea, button {
  font-family: GelasioRegular, sans-serif;
}

button, .btn {
  display: inline-block;
  background-color: hsl(20, 32%, 66%);
  padding: 10px 18px;
  font-size: 1.8rem;
  outline: unset;
  border: none;
  border-radius: 9px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.btn.outline {
  border: 1px hsl(20, 32%, 66%) solid;
  background-color: var(--background-color);
  color: var(--headline-color);
}

button:hover, .btn:hover {
  background-color: hsl(21, 32%, 56%);
  transform: scale(1.03);
}

button:focus, .btn:focus {
  box-shadow: hsla(21, 32%, 56%, 0.4) 0 0 0 3px;
  outline: none;
}
