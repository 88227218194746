.background-color {
    background-color: var(--background-color);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('./assets/images/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.background > div {
    position: absolute;
    inset: 0;
    background-color: hsla(var(--bg-hsl), 0.8);
}

.landing {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 100svh;
    margin: auto;
}

.landing > p {
    margin: 50px 0;
    width: 70%;
    font-size: 2.5rem;
}

.actions > .btn {
    margin-left: 20px;
    margin-right: auto;
    font-size: 2.2rem;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin-top: 35px;
}

.about {
    display: flex;
    justify-content: space-evenly;
    
}

.about-me {
    text-align: justify;
    width: 50%;
    margin: auto 50px auto 20px;
}

.experiences {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    gap: 50px 0;
}

.experience {
    width: 30%;
}

/* .experience ~ .experience {
    margin-left: 30px;
} */

.experience > .space-down {
    margin-bottom: 15px;
}

.experience > .space-up {
    margin-top: 15px;
}

.experience > h3,h4 {
    font-weight: unset;
}
.experience > h3 {
    font-size: 3rem;
}

.experience > h4 {
    font-size: 2.2rem;
}

.experience .skills > span {
    margin-top: 10px;
}

.educations {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.middle-line {
    --eduction-bg: #393636ff;
    position: relative;
    width: 5px;
    background-color: var(--eduction-bg);
    height: max-content;
}

.box {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: var(--eduction-bg);
    color: var(--headline-color);
    border-radius: 15px;
    margin-bottom: 50px;
    padding: 15px;
}

.box.cert {
    --offset: 80px;
    left: -31px;
}

.year {
    --offset: 100px;
    font-size: 2.5rem;
    left: -40px;
}

.semester {
    --offset: 55px;
    font-size: 2.2rem;
    padding: 10px 15px;
    left: -18.5px;
    margin-bottom: 75px;
}

.semester:has(.left) {
    text-align: right;
}

.semester > .education {
    line-height: 2;
    list-style: none;
}

.education {
    position: absolute;
    width: max-content;
}

.education:has(img) {
    display: flex;
    align-items: center;
}

.education > img {
    width: 18rem;
    margin: 20px;
    cursor: pointer;
}

.left {
    right: var(--offset);
}

.right {
    left: var(--offset);
}

@media screen and (max-width: 1190px) {
    .educations {
        align-items: baseline;
    }

    .left {
        left: var(--offset);
        text-align: left;
    }

    .semester {
        margin-bottom: 100px;
    }
    
}

@media only screen and (max-width: 900px) {
    .actions > .btn:last-child {
        margin-top: 10px;
    }

    .title {
        font-size: 4.6rem;
    }

    .landing > p {
        font-size: 2rem;
        width: 100%;
    }

    .experience {
        width: 100%;
    }

    .experience ~ .experience {
        margin-top: 50px;
        margin-left: 0;
    }

    .about {
        flex-wrap: wrap;
        justify-content: center;
    }

    .about-me {
        width: unset;
        margin: 0;
    }

    .education {
        width: 205px;
    }
    .semester {
        margin-bottom: 320px;
    }

    .year:nth-child(3) {
        margin-bottom: 100px;
    }
}