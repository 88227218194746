.skills {
    width: 50%;
}

.skills > article:first-of-type {
    border-radius: 10px 10px 0 0;
}

.skills > article:last-child {
    border-radius: 0px 0px 10px 10px;
}

.skill{
    --_background: var(--skill-bg);
    background-color: var(--_background);
}

.skillHeader {
    padding: 20px 0 0px 10px;
}

.skillTags {
    padding: 10px 10px 25px 10px;
}

.skillTags > span {
    margin-top: 5px;
}

@media only screen and (max-width: 900px) {
    .skills {
        margin-top: 50px;
        width: 100%;
    }
}